<template>
  <section
    v-if="headerNav && headerNav.title"
    class="fixed-common-header mshe-z-header j-branch-header"
    :class="{
      'user-personal-header': $route.name === 'SettingPersonalized',
    }"
  >
    <s-title-nav>
      <template
        v-if="!headerNav.isHideBack" 
        #prepend
      >
        <s-title-nav-item
          is-back-icon
          @click="goBack"
        />
      </template>

    
      <template #default>
        <h4>{{ headerNav.title }}</h4>
        <p 
          v-if="headerNav.subTitle"
          class="sub-title"
        >
          {{ htmlDecode({text: headerNav.subTitle}) }} 
        </p>
      </template>
     
      <template #suffix>
        <div class="header-right">
          <template v-if="$route.name.indexOf('UserWishlist') > -1">
            <!-- 纯文字按钮 -->
            <a
              v-show="wishlistTextBtn && !wishlistTextBtn.isHide"
              href="javascript:void(0);"
              class="header-btn-text"
              :style="wishlistTextBtn && wishlistTextBtn.style || {}"
              @click.stop="wishlistTextBtn && wishlistTextBtn.evt && wishlistTextBtn.evt()"
            >
              {{ wishlistTextBtn && wishlistTextBtn.title }}
            </a>

            <!-- 选择按钮 -->
            <a
              v-show="wishlistSelectBtn && !wishlistSelectBtn.isHide"
              href="javascript:void(0);"
              @click.stop="wishlistSelectBtn && wishlistSelectBtn.evt && wishlistSelectBtn.evt()"
            >
              <i class="suiiconfont sui_icon_nav_select_24px"></i>
            </a>

            <!-- 购物车 -->
            <a
              v-show="wishlistBagBtn && !wishlistBagBtn.isHide"
              :href="`${langPath}/cart`"
              class="animated header-bag j-header-bag"
              @click.stop="wishlistBagBtn && wishlistBagBtn.evt && wishlistBagBtn.evt()"
            >
              <i class="suiiconfont sui_icon_nav_shoppingbag_24px"></i>
              <em class="j-header-cart-num mshe-hide">0</em>
            </a>
          </template>

          <template v-else-if="$route.name == 'UserWallet'">
            <a :href="`${langPath}/product/article/${IS_RW ? 486 : 609}`">
              <i class="suiiconfont sui_icon_nav_doubt_24px"></i>
            </a>
          </template>
          <template v-else-if="$route.name === 'Points'">
            <i
              class="suiiconfont sui_icon_nav_doubt_24px"
              @click="goBonus"
            ></i>
          </template>
          <!-- 个性化设置页面 -->
          <template v-else-if="$route.name === 'SettingPersonalized'">
            <a
              :href="`${langPath}/cart`"
              class="animated header-bag j-header-bag"
            >
              <i>
                <sui_icon_nav_cart_24px
                  size="24px"
                  :color="'#222'"
                />
              </i>
              <em class="j-header-cart-num mshe-hide">0</em>
            </a>
          </template>
          <slot name="right"></slot>
        </div>
      </template>
    </s-title-nav>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { htmlDecode, getQueryString } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { STitleNavItem } from '@shein-aidc/sui-title-nav-item/mobile'
import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
import { sui_icon_nav_cart_24px } from '@shein-aidc/icon-vue3'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

const { IS_RW, language, GB_cssRight } = gbCommonInfo

export default defineComponent({
  name: 'PageHeader',
  components: {
    sui_icon_nav_cart_24px,
    STitleNav,
    STitleNavItem
  },
  data () {
    return {
      IS_RW,
      GB_cssRight,
      toAbt: null,
      language,
      pointsRuleLink: ''
    }
  },
  computed: {
    ...mapState('root', ['headerNav']),

    /**
     * 收藏页文字按钮
     */
    wishlistTextBtn () {
      return this.headerNav.btns && this.headerNav.btns.find((i) => i.type === 'text')
    },

    /**
     * 收藏页选择按钮
     */
    wishlistSelectBtn () {
      return this.headerNav.btns && this.headerNav.btns.find((i) => i.type === 'select')
    },

    /**
     * 收藏页购物车按钮
     */
    wishlistBagBtn () {
      return this.headerNav.btns && this.headerNav.btns.find((i) => i.type === 'bag')
    }
  },
  created () {
    this.createToAbtClick()
    
    if(this.$route.name === 'Points'){
      this.getPointsRuleLink()
    }
  },
  beforeUnmount () {
    this.toAbt = null
  },
  methods: {
    htmlDecode,
    async getPointsRuleLink(){
      const abtInfo = await getUserAbtData()
      const OrderRegularPoints = abtInfo?.OrderRegularPoints
      this.pointsRuleLink = OrderRegularPoints?.param?.PointsRuleLink
    },
    goBack () {
      markPoint('toNextPageClick', 'public')
      if (getQueryString({ key: 'page' }) == 'checkoutPage' || getQueryString({ key: 'from' }) === 'exchange' || getQueryString({ key: 'fromPage' }) === 'returnPage') {
        return history.back()
      }
      // if (['TicketDetail'].indexOf(this.$route.name) > -1) {
      //   return this.$router.push(this.langPath + '/user/tickets')
      // }
      // if (['Tickets'].indexOf(this.$route.name) > -1) {
      //   return this.$router.push(this.langPath + '/user/support')
      // }
      if(['TicketDetailReply'].indexOf(this.$route.name) > -1){
        window.close()
      }
      if (['UserWallet', 'UserOrdersList', 'Support'].indexOf(this.$route.name) > -1) {
        return this.$router.push(this.langPath + '/user/index')
      }
      if (this.$route.name == 'Coupon') {
        return history.back()
      }
      // if (this.$route.name == 'UserTickets') {
      //   return this.$router.push(this.langPath + '/user/support')
      // }
      if (['UserOrdersList'].includes(this.$route.name)) {
        return this.$router.push(this.langPath + '/user/index')
      }
      if (['UserSupport'].includes(this.$route.name) && this.headerNav && this.headerNav.fromName == 'UserTickets') {
        return this.$router.push(this.langPath + '/user/index')
      }
      if (this.$route.name == 'UserOrdersReturn') {
        if (!(this.$route.query.pf && this.$route.query.pf == 'listordetail')) {
          return this.$router.push(this.langPath + '/user/orders/list')
        }
      }
      if (this.$route.name == 'UserServicePhone') {
        // return this.$router.push(this.langPath + '/user/support')
      }
      if (this.$route.name == 'UserGiftcardOrdersList') {
        return this.$router.push(this.langPath + '/user/orders/list')
      }
      if (this.$route.name == 'Setting') {
        if (typeof GBGaSend !== 'undefined') {
          GBGaSend({
            category: 'Settings',
            action: 'back'
          })
        }
        return this.$router.push(this.langPath + '/user/index')
      }
      // if ([this.langPath + '/user/preference', this.langPath + '/user/body_shape', this.langPath + '/user/measurement'].includes(this.$route.path)) {
      //   return this.$router.push(this.langPath + '/user/account_setting')
      // }
      // if (this.$route.path == this.langPath + '/user/account_setting') {
      //   return this.$router.push({ path: this.langPath + '/user/index', params: { page_from_me: 1 } })
      // }
      if (this.$route.name == 'Payment' || this.$route.name === 'UserAbtInfo' || this.$route.name === 'AddressBook') {
        if (this.$route.name === 'AddressBook' && getQueryString({ key: 'from' }) === 'selectAddress') {
          return this.$router.back()
        }
        return this.$router.push(this.langPath + '/user/setting')
      }
      if (this.$route.name == 'Review_already') {
        return this.$router.back()
      }
      if (this.$route.name == 'orderReturnRefundRecord') {
        return this.$router.push(this.langPath + '/user/orders/detail/' + this.$route.params.billno)
      }
      if (this.$route.name == 'UserReference') {
        if (typeof GBGaSend !== 'undefined') {
          GBGaSend({
            category: 'EditProfile',
            action: '	ClickBack-MyReference'
          })
        }
        if (typeof sa !== 'undefined') {
          sa('send', {
            activity_name: 'click_back',
            activity_param: ''
          }, { beacon: 1 })
        }
      }
      if(this.$route.name === 'SettingSecurity'){

        // 由于邮箱换绑在线问答场景会跳转到sdk页面，再跳转回来，会出现回退到sdk页面的问题
        return this.$router.push(this.langPath + '/user/setting')
      }

      if (this.$route.name == 'SettingPersonalized') {
        // 点击回退按钮，触发埋点
        if (typeof sa !== 'undefined') {
          sa('send', {
            activity_name: 'click_button',
            activity_param: {
              button_type: 1,
            }
          })
        }
      }
      this.$router.back()
    },
    async goBonus() {
      if(this.pointsRuleLink) {
        window.location.href = `${this.langPath}/article-${this.pointsRuleLink}.html`
      }else{
        await this.getPointsRuleLink()
        window.location.href = this.pointsRuleLink ? `${this.langPath}/article-${this.pointsRuleLink}.html` : `${IS_RW ? 'https://m.romwe.com' : 'https://m.shein.com'}${this.langPath}/bonus-point-program-a-371.html`
      }
    },
    createToAbtClick () {
      // 点击5次闭包 箭头函数 this指向当前vue实例
      const debounceCount = (fn, wait) => {
        let timer = null
        let count = 0
        return () => {
          count++
          if (timer) {
            clearTimeout(timer)
            timer = null
          }
          if (count === 5) {
            count = 0
            return fn()
          }
          timer = setTimeout(function () {
            count = 0
          }, wait)
        }
      }
      // 点击5次跳转
      const abtAction = () => {
        if (this.$route.name === 'Setting') {
          this.$router.push(`${this.langPath}/user/abt-password`)
        }
      }
      this.toAbt = debounceCount(abtAction, 2 * 1000)
    }
  },
})
</script>
<style lang="less">
.sub-title {
  color: #9b9b9b;
  font-size: 10px;
}
.header-right{
  a {
    text-decoration: none;
  }
}
</style>
